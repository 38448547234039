<template>
  <div class="home">
    <img alt="Hoshino logo" src="@/assets/logo.webp" />
    <p><i>建设中...</i></p>
    <h1>Welcome to Hoshino Clanbattle v4</h1>
    <p>点击导航中的“指南”查看说明手册</p>
    <p>点击导航中的“关于”联系我们</p>
    <p>
      <del style="color: #f7f7f7; opacity: 0.05"
        >有没有软软香香可可爱爱身着水手服百褶裙白色过膝袜白毛猫耳画萝莉自身也恰好是萝莉的画师帮忙画个logo呀？（↑）</del
      >
    </p>
    <el-input style="width: 30%" />
    <el-button>确认</el-button>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>
